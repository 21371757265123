import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loader } from './components/Loader/Loader';
// Импортируем компоненты страниц
const Public = React.lazy(() => import('./public/pages/Public'));
const Performers = React.lazy(() => import('./public/pages/Performers'));
const Uslugi = React.lazy(() => import('./public/pages/Uslugi'));
const About = React.lazy(() => import('./public/pages/About'));
const Zadaniya = React.lazy(() => import('./public/pages/Zadaniya'));
const TopExecutors = React.lazy(() => import('./public/pages/TopExecutors'));
const Usluga = React.lazy(() => import('./public/pages/Usluga'));
const Task = React.lazy(() => import('./public/pages/Task'));
const NewJobs = React.lazy(() => import('./pages/NewJobs'));
const WorkJobs = React.lazy(() => import('./pages/WorkJobs'));
const WaitJobs = React.lazy(() => import('./pages/WaitJobs'));
const CloseJobs = React.lazy(() => import('./pages/CloseJobs'));
const Profile = React.lazy(() => import('./pages/account/Profile'));
const Login = React.lazy(() => import('./pages/account/Login'));
const NotififcationPage = React.lazy(() => import('./pages/NotififcationPage'));
const OrdersPage = React.lazy(() => import('./pages/client/OrdersPage'));
const CreatePage = React.lazy(() => import('./pages/client/CreatePage'));
const ProfileClient = React.lazy(() => import('./pages/client/ProfileClient'));
const OrderPage = React.lazy(() => import('./pages/client/OrderPage'));
const BlogPage = React.lazy(() => import('./public/pages/Blog'));
const InfoPage = React.lazy(() => import('./public/pages/Info'));

const App = () => {

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader isLoading isPage></Loader>}>
        <Routes>
          <Route exact path="/" element={<Public />} />
          <Route path="/ispolniteli" element={<Performers />} />
          <Route path="/ispolniteli/zadaniya/" element={<Zadaniya />} />
          <Route path="/ispolniteli/top/" element={<TopExecutors />} />
          <Route path="/about/" element={<About />} />
          <Route path="/uslugi" element={<Uslugi />} />
          <Route path="/uslugi/:url/" element={<Usluga />} />
          <Route path="/ispolniteli/zadaniya/:url/" element={<Task />} />
          <Route path="/find" element={<NewJobs></NewJobs>} />
          <Route path="/work" element={<WorkJobs />} />
          <Route path="/wait" element={<WaitJobs />} />
          <Route path="/close" element={<CloseJobs />} />
          <Route path="/authorize/user/" element={<Profile />} />
          <Route path="/authorize/user/:id/" element={<Profile />} />
          <Route path="/authorize/" element={<Profile />} />
          <Route path="/authorize/auth" element={<Login />} />
          <Route path="/client/" element={<OrdersPage />} />
          <Route path="/client/create/" element={<CreatePage />} />
          <Route path="/client/order/:id/" element={<OrderPage />} />
          <Route path="/client/profile/" element={<ProfileClient />} />
          <Route path="/notifications" element={<NotififcationPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:url/" element={<InfoPage />} />
          <Route path="/documents/:url/" element={<InfoPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
